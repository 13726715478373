<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="用户昵称：">
        <el-input v-model="form.keyword" size="small" placeholder="请输入用户昵称"></el-input>
      </el-form-item>
      <el-form-item label="用户来源：">
        <el-select v-model="form.comefrom" size="small" placeholder="请选择">
          <el-option v-for="item in user_source" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker @change="getTime(0, $event)" v-model="register_time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="会员卡状态：">
        <el-select v-model="form.status" size="small" placeholder="请选择">
          <el-option v-for="item in status" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上次登录时间：">
        <el-date-picker @change="getTime(1, $event)" v-model="login_time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="会员等级：">
        <el-select size="small" v-model="form.level" placeholder="请选择">
          <el-option v-for="item in userGrade" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="45px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancleSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="userList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column width="200" label="用户信息" align="center">
        <template slot-scope="scope">
          <div class="user-info">
            <el-image :src="scope.row.avatar ? scope.row.avatar : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/userDefault.png '"></el-image>
            <span>{{ scope.row.nickname || '--' }}</span>
            <el-tag v-show="scope.row.external_contact" size="small" style="margin-left: 5px" type="success" effect="plain">企微好友</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.mobile || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_amount" label="累计消费金额" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.order_amount || '0' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_num" label="累计订单数" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.order_num || '0' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mid" label="用户身份" min-width="120" align="center">
        <template slot-scope="scope">
          <el-image v-if="scope.row.mid" :src="require('@/assets/image/vip.png')"></el-image>
          <span v-else>普通用户</span>
        </template>
      </el-table-column>
      <el-table-column prop="level_text" label="会员等级" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.level_text ? scope.row.level_text : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="余额" min-width="80" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.balance || '0' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="grow_value" label="成长值" min-width="100" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.grow_value || '0' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="会员卡状态" align="center" width="120">
        <template slot-scope="scope">
          {{ scope.row.status == 1 ? '正常' : scope.row.status == 0 ? '待激活' : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="comefrom" label="来源" min-width="80">
        <template slot-scope="scope">
          <el-image :src="scope.row.comefrom_icon"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="sales" label="会员有效期" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.status != 1 ? '--' : getDateformat(scope.row.end_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="注册时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.status != 1 ? '--' : scope.row.create_time }}
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="授权时间" align="center" width="160">
        <template slot-scope="scope">
          {{ getDateformat(scope.row.update_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="170" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button @click="actionsType(scope.row, 1, scope.$index)" type="text" size="small">详情</el-button>
          <el-button @click="actionsType(scope.row, 3, scope.$index)" type="text" size="small">{{ scope.row.isblack ? '恢复' : '冻结' }}</el-button>
          <el-button v-show="scope.row.mid" @click="actionsType(scope.row, 4, scope.$index)" type="text" size="small">送余额</el-button>
          <el-button @click="actionsType(scope.row, 5, scope.$index)" type="text" size="small">赠优惠券</el-button>
          <el-button @click="actionsType(scope.row, 6, scope.$index)" v-show="scope.row.external_contact" type="text" size="small">联系 {{ scope.row.sex == 1 ? '他' : '她' }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging class="paging" :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <SendMessage v-if="showSendMessage" :external_userid="external_userid"></SendMessage>
    <Rechange :showRecharge="showRecharge" :id="currentInfo.id" :log="log" :rechange_num="rechange_num" :balance="currentInfo.balance" @closePopup="closePopup" @getList="getList"></Rechange>
    <SendCoupon :showCoupon="showCoupon" :id="currentInfo.id" :coupon_id="coupon_id" :coupon_num="coupon_num" @closePopup="closePopup"></SendCoupon>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
import SendCoupon from './components/SendCoupon';
import Rechange from './components/Rechange';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import fash from '@/assets/image/fash.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
import SendMessage from './components/sendMessage';
export default {
  components: {
    Paging,
    SendCoupon,
    Rechange,
    SendMessage,
  },
  data() {
    return {
      total: 0,
      login_time: '',
      register_time: '',
      userGrade: [],
      userList: [],
      page: 1,
      rows: 10,
      groupType: '',
      currentInfo: {},
      showRecharge: !1,
      showCoupon: !1,
      coupon_id: '',
      coupon_num: '', //赠送优惠券数量
      log: '',
      rechange_num: '',
      cluster_id: '',
      form: {
        keyword: '', //用户信息
        comefrom: 'all', //1—wechat 2—ali 3—fastshop
        starttime: '', //注册开始时间
        endtime: '', //注册结束时间
        status: '2', //1正常 0待激活
        ls_starttime: '', //上次登陆时间开始
        ls_endtime: '', //上次登陆时间结束
        level: '', //等级
      },
      showSendMessage: !1,
      external_userid: '',
      user_source: [
        {
          val: 'all',
          label: '全部',
        },
        {
          val: '1',
          label: '微信',
        },
        {
          val: '2',
          label: '支付宝',
        },
        {
          val: '3',
          label: '快应用',
        },
        {
          val: '4',
          label: '抖音',
        },
        {
          val: '5',
          label: 'QQ',
        },
        {
          val: '6',
          label: '百度',
        },
      ],
      status: [
        {
          val: '2',
          label: '全部',
        },
        {
          val: '1',
          label: '正常',
        },
        {
          val: '0',
          label: '待激活',
        },
      ],
    };
  },
  created() {
    let utils = this.$store.state.utils;
    if (utils.is_record) {
      this.page = utils.page;
      this.rows = utils.rows;
      utils.is_record = !1;
    }
    console.log(this.$route.query);
    this.groupType = this.$route.query.type;
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    closePopup() {
      this.showCoupon = !1;
      this.showRecharge = !1;
    },
    search() {
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    cancleSearch() {
      for (let i in this.form) this.form[i] = '';
      this.form.comefrom = 'all';
      this.login_time = '';
      this.register_time = '';
      this.getList();
    },
    getTime(type, val) {
      if (type) {
        this.form.ls_starttime = val[0].getTime() / 1000;
        this.form.ls_endtime = val[1].getTime() / 1000;
      } else {
        this.form.starttime = val[0].getTime() / 1000;
        this.form.endtime = val[1].getTime() / 1000;
      }
    },
    getList() {
      let data = JSON.parse(JSON.stringify(this.form));
      if (data.status == '2') delete data.status;
      if (this.groupType == 1) data.cluster_tj_id = this.$route.query.cluster_id;
      if (this.groupType == 2) data.cluster_id = this.$route.query.cluster_id;
      data.page = this.page;
      data.rows = this.rows;
      if (data.comefrom == 'all') delete data.comefrom;
      console.log(this.$api.user.userList)
      this.$axios.post(this.$api.user.userList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map(item => {
            if (item.comefrom == 1) {
              item.comefrom_icon = wx;
            }
            if (item.comefrom == 2) {
              item.comefrom_icon = ali;
            }
            if (item.comefrom == 3) {
              item.comefrom_icon = fash;
            }
            if (item.comefrom == 4) {
              item.comefrom_icon = douyin;
            }
            if (item.comefrom == 5) {
              item.comefrom_icon = qq;
            }
            if (item.comefrom == 6) {
              item.comefrom_icon = baidu;
            }
          });
          this.userList = list;
          if (!this.userGrade.length) this.userGrade = res.result.level;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    actionsType(row, type, index) {
      this.currentInfo = row;
      switch (type) {
        //编辑
        case 1:
          this.$router.push({
            path: '/user/userDetail',
            query: {
              id: row.id,
              page: this.page,
              rows: this.rows,
            },
          });
          break;
        //送积分
        case 2:
          break;
        //冻结/恢复
        case 3:
          this.$confirm(`${row.isblack ? '请确认是否恢复此用户，恢复后用户可以正常下单？' : '请确认是否冻结此用户，冻结后，此用户无法下单？'}`, `${row.isblack ? '恢复' : '冻结'}`, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$axios
              .post(this.$api.user.setblack, {
                black: row.isblack ? 0 : 1,
                id: row.id,
              })
              .then(res => {
                if (res.code == 0) {
                  this.userList[index].isblack = row.isblack ? 0 : 1;
                  this.$message({
                    message: `${row.isblack ? '冻结成功' : ' 恢复成功'}`,
                    type: 'success',
                  });
                } else {
                  this.$message.error(res.msg);
                }
              });
          });

          break;
        //送余额
        case 4:
          this.showRecharge = !0;
          this.log = '';
          this.rechange_num = '';
          break;
        //赠送优惠券
        case 5:
          this.showCoupon = !0;
          this.coupon_id = '';
          this.coupon_num = '';
          break;
        //删除
        case 6:
          this.external_userid = row.external_contact.external_userid;
          this.showSendMessage = !0;
          break;
        default:
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.user-info {
  display: flex;
  align-items: center;
  .el-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
  span:nth-child(2) {
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .wxwork {
    width: 30px;
    height: 30px;
  }
}
</style>
